Nav {
    text-align: center;
}

.Navigation-link:hover{
    cursor: pointer;
    transform: translate(-2px, -2px);
    box-shadow: 5px 5.5px 2.5px #EF709D;
    border-radius: 10%;
    color: #EF709D;

}