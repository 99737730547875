body {
  -webkit-font-smoothing: antialiased;
}

.App {
  background-color: white;
}

p {
  color: #190B28;
}

b {
  color: #00BFFF;
}

h1, h2, h3 {
  color: #EF709D;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
