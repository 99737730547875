.ContactInfoButtons-email:hover {
    cursor: pointer;
}

.ContactInfoButtons img {
    min-width: 4%;
    margin: 1.5%;
}

.ContactInfoButtons img:hover {
    transform: translate(-2px, -2px);
    box-shadow: 5px 5.5px 2.5px #EF709D;
    border-radius: 10%;
}