.Carousel-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Carousel-main .Carousel-arrow {
  cursor: pointer;
}

.Carousel-main .Carousel-arrow:hover {
  cursor: pointer;
  transform: rotate(-3.5deg);
}

.Carousel-arrow-div{
  align-items: center;
}

.Carousel-img {
  min-width: 200rem;
}

.Carousel-Card {
  max-width: 200rem;
  min-width: 200rem;
  max-height: 200rem;
  min-height: 200rem;
}

.hidden {
  opacity: 0;
  pointer-events: none;
}

.bi {
  font-size: 35px
}
